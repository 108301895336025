<template>
  <CHeading :heading="isListElement ? 'li' : 'article'" class="break-inside-avoid">
    <slot name="icon">
      <div v-if="showIcon" :class="[justification]" class="flex fill-sand-300 stroke-sand-300 mb-10">
        <img :src="iconPath" class="logo" width="50" height="50" alt="Title">
      </div>
    </slot>
    <CHeading v-if="title" class="mb-2" :class="[tmpTitleStyle]" :heading="heading">{{ title }}</CHeading>
    <div :class="[spaceY]">
      <slot />
    </div>
  </CHeading>
</template>

<script setup lang="ts">
export interface Props {
  heading?: string,
  isListElement?: boolean,
  justification?: string,
  showIcon?: boolean,
  spaceY?: string,
  title?: string,
  titleStyle?: string,
}
enum Icons {
  Info = '/images/circle-check.svg',
}
const props = withDefaults(defineProps<Props>(), {
  heading: 'h3',
  justification: 'justify-center',
  spaceY: 'grid gap-4',
});
const iconPath = Icons.Info
const tmpTitleStyle = props.titleStyle
  ?? (props.showIcon
    ? 'text-2xl text-sand-300'
    : 'text-xl font-bold')
</script>
